<template>
  <div>
    <b-card class="mt-2">
      <b-row class="mb-2">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Groups</th>
            </tr>
          </thead>
        </table>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group class="mb-40">
            <b-input-group>
              <b-form-input v-model.lazy="groupName" type="text" placeholder="Add Group" :state="groupNameState"></b-form-input>
              <b-input-group-append>
                <b-button @click="addGroup()" variant="success">Add +</b-button>
              </b-input-group-append>
              <b-form-invalid-feedback id="input-live-feedback">
                Enter at least 3 letters
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group class="mb-40">
            <b-input-group>
              <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>
              <b-input-group-append>
                <b-button @click="filter = ''" variant="warning">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        :items="allGroups"
        :small="true"
        responsive
        style="padding-bottom:30px"
        class="position-relative "
        head-variant="dark"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        :fields="column"
        striped
        hover
        :current-page="currentPage"
        :bordered="true"
        :per-page="perPage"
      >
        <template v-slot:cell(groupName)="data">
          <b-form-group v-if="editingGroupId === data.item.id" style="margin-bottom: 0 !important">
            <b-input-group>
              <b-form-input v-model.lazy="data.item.groupName" type="text" placeholder="Update Item" :state="editGroupNameState(data.item.groupName)"></b-form-input>
              <b-input-group-append>
                <b-button @click="editGroup(data.item)" variant="info">Update</b-button>
              </b-input-group-append>
              <b-input-group-append>
                <b-button @click="editingGroupInput({ id: 0 })" variant="warning">Cancel</b-button>
              </b-input-group-append>
              <b-form-invalid-feedback id="input-live-feedback">
                Enter at least 3 letters
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <span class="font-weight-bold" v-else>
            {{ data.item.groupName }}
          </span>
        </template>
        <template v-slot:cell(groupNumbers)="data">
          <span class="font-weight-bold">
            {{ data.item.groupNumbers }}
          </span>
        </template>
        <template v-slot:cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="editingGroupInput(data.item)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Edit Group </span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteGroup(data.item.id)">
              <feather-icon icon="DeleteIcon" />
              <span class="align-middle ml-50">Delete Group</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start" style="padding-left: 0;"> Total entries : {{ totalRows }} </b-col>

          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end" style="padding-right: 5px;">
            <b-pagination first-number last-number :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import { BFormGroup, BTable, BRow, BCol, BFormSelect, BPagination, BIcon, BSkeletonIcon } from 'bootstrap-vue';
import settingsStoreModule from '../settingsStoreModule';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import _ from 'lodash';

import axiosIns from '@/libs/axios';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BIcon,
    BSkeletonIcon,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      edit: null,
      groupName: '',
      editGroupName: '',
      filter: null,
      filterOn: [],
      perPage: 10,
      allGroups: [],
      editingGroupId: 0,
      currentPage: 1,
      pageOptions: [5, 10, 15, 20],
      column: [
        { key: 'groupName', sortable: true, label: 'Group Name' },
        {
          key: 'groupNumbers',
          sortable: true,
          label: 'Number of Groups',
          thClass: 'text-center',
          tdClass: 'text-center pr-0',
        },
        {
          key: 'actions',
          label: 'Actions',
          thClass: 'text-center',
          tdClass: 'text-center pr-0',
        },
      ],
    };
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },
  computed: {
    totalRows() {
      return this.allGroups.length;
    },
    groupNameState() {
      if (this.groupName === '') {
        return null;
      } else {
        return this.groupName.length > 3 ? true : false;
      }
    },
  },
  methods: {
    editGroupNameState(name) {
      if (name === '') {
        return null;
      } else {
        return name.length > 3 ? true : false;
      }
    },
    editingGroupInput(group) {
      if (this.editingGroupId !== group.id) {
        this.editingGroupId = group.id;
      }
    },
    editGroup(group) {
      if (group.groupName !== '' && group.groupName.length > 3) {
        store
          .dispatch('settings/updateGroupItem', group)
          .then((res) => {
            this.getGroups();
            this.editGroupName = '';
            this.editingGroupId = 0;
            this.editToastMessage('Group');
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.editGroupNameState(group.groupName);
      }
    },
    getGroups() {
      store
        .dispatch('settings/fetchGroups', [])
        .then((res) => {
          this.allGroups = [];
          res.data.forEach((group) => {
            this.allGroups.unshift({
              id: group.id,
              groupName: group.groupName,
              status: group.status,
              groupNumbers: group.questions.length,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onFiltered(filteredItems) {
      //   this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addGroup() {
      if (this.groupName !== '' && this.groupName.length > 3) {
        store
          .dispatch('settings/addNewGroupItem', {
            groupName: _.capitalize(this.groupName.trim()),
          })
          .then((response) => {
            if (response.status == 201) {
              this.getGroups();
              this.toastMessage('New Group');
              this.groupName = '';
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.groupNameState;
      }
    },
    deleteGroup(id) {
      const self = this;
      this.$swal({
        title: 'Are you sure you want to delete this group?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch('settings/deleteGroupItem', id)
            .then((res) => {
              self.getGroups();
              self.$swal.fire('Deleted!', 'Group has been deleted.', 'success');
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Added Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
    editToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Updated Successful',
          icon: 'CheckIcon',
          variant: 'success',
        },
      });
    },
  },
  created() {
    this.getGroups();
  },
};
</script>
